import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, body, image, isReversed } = content || {}
  const isReversedClass = isReversed ? styles.isReversed : ''
  return (
    <Inview className='pad-v-standard fade-in up'>
      <div className={`container grid-12 ${styles.grid} ${isReversedClass}`}>
        <div className={styles.text}>
          <h3>{title}</h3>
          <div className='p-smaller'>
            <MarkdownWrap body={body} />
          </div>
        </div>
        <div className={styles.image}>
          <ImageWrap image={image} aspectRatio={5 / 6} />
        </div>
      </div>
    </Inview>
  )
}

export default Block
