import React from 'react'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { pageContext, layoutProps } = props
  const { designer, designers, collections, locale } = pageContext
  const designerLocale = designer.frontmatter[locale]
  const strings = {
    en: {
      designTeam: 'Design Team',
    },
    zh: {
      designTeam: '设计团队',
    },
  }
  const tr = strings[locale]

  const content = {
    designer: designerLocale,
    designers,
    collections,
    title: `${tr.designTeam} - ${designerLocale.name}`,
    seo: designerLocale.seo,
  }

  return (
    <>
      <Seo content={content} />
      <PageTemplate
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType
