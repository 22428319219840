import React from 'react'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import TextImageThumb from 'blocks/TextImageThumb/template'
import BannerTextOnlySmaller from 'blocks/BannerTextOnlySmaller/template'
import ImagesUneven from 'blocks/ImagesUneven/template'
import Pagination from 'components/Pagination'
import * as styles from './styles.module.scss'
import { getPaginationItems } from 'js/utils'

const ContentType = (props) => {
  const { content, locale } = props
  if (!locale) locale = 'en'
  const { designer, designers, collections } = content
  const paginationItems = getPaginationItems(designer, designers, locale)
  const designerCollections = collections.filter(
    (collection) =>
      collection.frontmatter[locale].designer?.slug === designer.slug
  )

  const strings = {
    en: {
      about: 'About',
      designTeam: 'Design Team',
      collections: 'Collections',
      by: 'by',
      by2: '',
      designer: 'Designer',
    },
    zh: {
      about: '关于',
      designTeam: '设计团队',
      collections: '系列',
      by: '由',
      by2: '设计',
      designer: '设计师',
    },
  }
  const tr = strings[locale]

  const blockContent = {
    bannerTextOnly: {
      pretitle: tr.about,
      pretitleLink: '/about',
      title: tr.designTeam,
    },
    textImageThumb: {
      title: designer.name,
      body: designer.bio,
      image: designer.image,
      isReversed: true,
    },
    bannerTextOnlySmaller: {
      pretitle: tr.collections,
      title: `${tr.by} ${designer.name} ${tr.by2}`,
    },
    designerCollections: designerCollections.map((collection) => {
      return {
        pageLink: `/collections/${collection.frontmatter[locale].slug}`,
        pretitle:
          collection.frontmatter[locale].productDiv.data.frontmatter[locale]
            .titleShorter,
        title: collection.frontmatter[locale].title,
        images: collection.frontmatter[locale].images,
      }
    }),
    pagination: {
      pretitle: tr.designer,
      prev: {
        pageLink: `/designers/${paginationItems.prevItem.frontmatter[locale].slug}`,
        title: paginationItems.prevItem.frontmatter[locale].name,
      },
      next: {
        pageLink: `/designers/${paginationItems.nextItem.frontmatter[locale].slug}`,
        title: paginationItems.nextItem.frontmatter[locale].name,
      },
    },
  }

  return (
    <div className={styles.page}>
      <BannerTextOnly content={blockContent.bannerTextOnly} />
      <TextImageThumb content={blockContent.textImageThumb} />
      {blockContent.designerCollections.length > 0 && (
        <BannerTextOnlySmaller content={blockContent.bannerTextOnlySmaller} />
      )}
      {blockContent.designerCollections.map((item, i) => (
        <ImagesUneven content={item} key={i} />
      ))}
      <Pagination content={blockContent.pagination} locale={locale} />
    </div>
  )
}

export default ContentType
